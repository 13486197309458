<template>
  <div class="page">
    <van-cell-group title="网站二维码">
      <div class="tc">
        <vue-qr :size="220" colorDark="#1989fa" text="http://75pop.com/m"></vue-qr>
      </div>
      <van-cell>
        <p>
          <van-tag type="primary">用法</van-tag>可用 “微信/支付宝” 识别访问
        </p>
        <p>
          <van-tag type="primary">区别</van-tag>默认使用 “微信支付/支付宝” 付款
        </p>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="客服联系方式">
      <van-cell @click="doCopy('13505418658')" is-link title="手机/微信" value="13505418658"></van-cell>
      <van-cell @click="doCopy('leiyunjn')" is-link title="微信号" value="leiyunjn"></van-cell>
      <van-cell title="微信中长按可直接对话" title-class="tc"></van-cell>
      <van-cell>
        <div class="ph-30 tc">
          <img alt class="w100" src="http://www.75pop.com/themes/pink/images/my_wx_qrcode_small.png" style="max-width: 250px" />
        </div>
      </van-cell>
      <van-cell @click="doCopy('jnleiyun')" is-link title="微信公众号" value="jnleiyun"></van-cell>
      <van-cell title="微信中长按可关注" title-class="tc"></van-cell>
      <van-cell>
        <div class="ph-30 tc">
          <img alt class="w100" src="http://www.75pop.com/data/afficheimg/1471899420770812775.png" style="max-width: 250px" />
        </div>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import vueQr from "vue-qr";
import { Cell, CellGroup, Notify } from "vant";
Vue.use(Cell).use(CellGroup).use(VueClipboard);
export default {
  name: "Kefu",
  components: { vueQr },
  data() {
    return {};
  },
  methods: {
    doCopy(text) {
      this.$copyText(text).then(
        () => {
          Notify({ type: "success", message: "号码复制成功" });
        },
        () => {
          Notify("复制失败");
        }
      );
    },
  },
};
</script>

<style>
</style>